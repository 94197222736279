<template>
  <div class="public-body">
    <div class="public-body__block" style="text-align: center;">
      <img src="@/assets/img/club-ruy-logo.svg" alt="logo" />
    </div>
    <div class="public-body__block">
      <div class="public-body__title  title">СОГЛАСИЕ</div>
      <div class="public-body__subt">на обработку персональных данных при использовании Автоматизированной информационной системы «Клуб Российского Союза Молодежи» Общероссийской общественной организации «Российский Союз Молодежи»
      </div>
      <p>Настоящим я (далее – субъект персональных данных) во исполнение требований Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» (с изменениями и дополнениями) свободно, своей волей и в своем интересе принял(а) решение о предоставлении своих персональных данных Общероссийской общественной организации «Российский Союз Молодёжи», адрес: г. Москва, ул. Маросейка, д. 3/13 (далее – РСМ), и даю свое согласие на обработку своих персональных данных, указанных на форме регистрации и/или в личном кабинете на сайте club.ruy.ru (далее – Сайт).</p>
      <p>Согласие распространяется на следующую информацию: фамилия, имя, отчество, дата рождения, телефон, электронная почта, фото, адреса места жительства, паспортные данные, ссылки на страницы в социальных сетях, сведения об образовании (учебные заведения, владение языками), сведения о документах, самостоятельно заполненных, сведения о карьере (список мест работы), размер одежды, размер обуви, семейное положение.</p>
      <p>Согласие предоставляется на обработку, т.е. осуществление следующих действий в отношении моих персональных данных: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, включая СМИ, размещение на Сайте, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных в соответствии с законодательством Российской Федерации.</p>
      <p>Персональные данные предоставляются в целях реализации РСМ уставных видов деятельности, направленных, в том числе, но не ограничиваясь, на объединение граждан и общественных объединений для содействия всестороннему развитию молодого человека, реализации его потенциала во всех сферах общественной жизни, защиты законных интересов и прав молодёжи и исполнения требований, установленных законодательством Российской Федерации.</p>
      <p>Я согласен с тем, что сбор и обработка моих персональных данных осуществляется в целях регистрации и предоставления доступа к функционалу Сайта, ведения истории социальной активности, предоставления возможности участия в мероприятиях, предоставления возможности вступить в члены РСМ и принимать участие в программах и проектах РСМ, направления на предоставленные мной контактные данные сообщений и смс-уведомлений, в том числе информационных и новостных рассылок, опросов, тестирований, приглашений на мероприятия, осуществления уставных видов деятельности РСМ, а также с целью подтверждения моей личности при посещении мероприятий.</p>
      <p>Я согласен на обработку своих персональных данных и их передачу третьим лицам в целях обеспечения их защищенного хранения, защищенного доступа к ним и функционирования Сайта, а также в целях обеспечения возможности участия в мероприятиях.</p>
      <p>Датой выдачи настоящего согласия на обработку моих персональных данных является дата моей регистрации на Сайте.</p>
      <p>Я ознакомлен с документами РСМ, устанавливающими порядок обработки персональных данных, а также с моими правами и обязанностями в этой сфере.</p>
      <p>Я ознакомлен(а) с тем, что:</p>
      <p>- настоящее согласие на обработку моих персональных данных, указанных в форме регистрации и личном кабинете на Сайте, направляемых (заполненных) с использованием Сайта, действует до дня его отзыва в письменной форме;</p>
      <p>- согласие может быть отозвано мною на основании письменного заявления в бумажном виде, направляемого почтовым отправлением по адресу: г. Москва, ул. Маросейка, д. 3/13, Общероссийская общественная организация «Российский Союз Молодёжи», или в виде отсканированной копии, направляемой по электронной почте на адрес <a href="webmaster@ruy.ru" target="_blank">webmaster@ruy.ru</a>.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Privacy'
}
</script>
